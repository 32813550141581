/* eslint-disable indent */

import { styled } from 'solid-styled-components';
import { theme, FlexAlignment,  } from '../../style/theme';
import { SectionStyleProps } from './section-types';
import { HeightType } from '../shared-types';
import { StyledGrid } from '../grid/grid.style';
import * as Responsive from './section.responsive.style';
import { blockBuffer, itemStyling } from '../grid-helpers';
import { overlayStyle } from '../../ui-components/layouts/background-image-overlay';
import { containerQueries } from '../../tools/check-container-support';
import { sidebarPaddingRightTablet } from '../../ui-components/layouts/sidebar.style';

const {palette} = theme;

const evaluatedHeight = (custom: string | undefined, type: HeightType | undefined) => {
    if (custom) return custom;
    if (type === 'fill') return '100%';
    return 'auto';
};

export const StyledSection = styled(StyledGrid)<SectionStyleProps>`
    position: relative;

    ${p => {        
        const height = evaluatedHeight(p.customHeight, p.heightType);
        const itemStylingProps = {
            hAlign: p.horizontalAlignment,
            equalHeight: p.equalHeight,
            spans: p.childSpans,
            backgroundValue: p.backgroundValue,
        };

        const blockBufferProps = {
            customHeight: p.customHeight,
            heightType: p.heightType,
            removeSpacingBlock: p.removeSpacingBlock,
            backgroundValue: p.backgroundValue
        };

        return `
            container-type: inline-size;
            *:has(> .atos-section) {
                container-type: inline-size;
            }
            height: ${height} !important;
            background: ${p.backgroundType === 'color' && p.backgroundValue ? palette[p.backgroundValue] : 'transparent'};

            ${p.backgroundType === 'image' && p.backgroundImageSrcData && p.backgroundImageSrcData.original !== '' ? `
                background-image: url(${p.backgroundImageSrcData.original});
                background-size: cover;
                background-position: ${p.focusPosition || 'center'};
                ${overlayStyle(p.overlayType)}
            ` : ''}

            ${p.verticalAlignment ? `align-items: ${FlexAlignment[p.verticalAlignment]};` : ''}
              
            ${itemStyling(itemStylingProps)}
            ${blockBuffer(blockBufferProps)}
            ${Responsive.smallDesktopCss(p.withSidebarMenu)}
            ${Responsive.tabletCss()}
            ${combineWithBelowSectionStyle(p.combineWithBelowSection)}
            ${columnPennantStyle(p.withPennant)}

            &.pad-top-for-hcp-header {
                &:first-child {
                    &:not(.section-with-color)  {
                        ${theme.mediaQueries.desktopMin} {
                            padding-top: 12rem;
                        }

                        padding-top: 8rem;
                    }

                    &.section-with-color  {
                        ${theme.mediaQueries.desktopMin} {
                            margin-top: 12rem;
                        }

                        margin-top: 8rem;
                    }
                }
            }
            
            ${p.customCss ? p.customCss : ''}
        `;
    }}
`;

const combineWithBelowSectionStyle = (combine: boolean | undefined) => {
    if (!combine) {
        return '';
    }

    return `
        padding-bottom: ${theme.grid.gapSizeMobile}rem !important;
        & > .atos-column {
            padding-bottom: ${Math.floor(theme.grid.standardUnit * 1.5 / 2)}rem;
        }
        & > .atos-column.round-corners:first-child {
            border-bottom-left-radius: unset;
        }
        & > .atos-column.round-corners:last-child {
            border-bottom-right-radius: unset;
            ${containerQueries('tabletMax')} {
                border-bottom-left-radius: unset !important;
            }
        }
        & + .atos-section {
            padding-top: 0px !important;
        }

        &:has(> .with-bg-color) {
            padding-bottom: 0px !important;
            & + .atos-section {
                & > .atos-column {
                    padding-top: ${Math.floor(theme.grid.standardUnit * 1.5 / 2)}rem;
                }
                & > .atos-column.round-corners:first-child {
                    border-top-left-radius: unset;
                    ${containerQueries('tabletMax')} {
                        border-top-right-radius: unset !important;
                    }
                }
                & > .atos-column.round-corners:last-child {
                    border-top-right-radius: unset;
                }
            }
        }
    `;
};

const columnPennantStyle = (withPennant: boolean | undefined) => {
    if (!withPennant) {
        return '';
    }

    return `
        ${theme.containerQueries.tabletMin} {
            & .atos-column .atos-column-flex {
                padding-top: 8.5rem;
            }

            & .atos-column.with-bg-color .atos-column-flex {
                padding-top: 2.5rem;
            }
        }

        ${theme.containerQueries.tabletMax} {
            & .atos-column:first-of-type .atos-column-flex {
                padding-top: 2.5rem;
            }

            & .atos-column:first-of-type {
                &:not(.with-bg-color) .atos-column-flex {
                    padding-top: 8.5rem;
                }
            }
        }
    `;
};

export const StyledPennantWrapper = styled.div`
    position: relative;
`;

export const StyledPennant = styled.div<{ displaySide?: 'left' | 'right'; withSidebar?: boolean }>`
    position: absolute;
    top: 4rem;

    ${theme.containerQueries.desktopMax} {
        top: 3rem;
    }

    ${theme.containerQueries.mobileMax} {
        top: 2.5rem;
    }

    ${p => p.displaySide === 'left' 
        ? 'left: 0;' 
        : 'right: 0;'
    }

    ${p => p.displaySide === 'left' && p.withSidebar 
        ? `
            ${theme.containerQueries.tabletMax} {
                left: ${sidebarPaddingRightTablet + 'rem'};
            }
            ${theme.mediaQueries.tabletMax} {
                left: 0;
            }
        ` : ''}
`;
