import { For, Match, Show, Switch, createEffect, createMemo, createSignal, on, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { TopCategory, eventsStore, setAvailableCategories, setAvailableLanguages, setAvailableTags } from '../../stores/events-store';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { ViewStyleButton } from '../../ui-components/view-style-button/view-style-button';
import { EventListCardData, Tag } from '../event/event-types';
import { EventsContainerProps } from './events-types';
import { StyledEventsContainer, StyledEventsList } from './events.styles';
import { extractTopCategories } from './helpers/categories-helper';
import { createGroupedEventCard } from './helpers/events-card-helper';
import { getUniqueLanguages, languageCodes } from './helpers/languages-helper';
import { getUniqueTags } from './helpers/tags-helper';
import { ListViewEventCard } from './list-view-event-card';
import { isPastEvent } from './helpers/is-past-event';
import { isOnDemandEvent } from './helpers/is-on-demand-event';
import { statusNameToStatusTranslated } from './helpers/translations-helper';

export const EventsContainer = (props: EventsContainerProps) => {
    const { viewport, localize } = useContext(AppContext);

    const [viewStyle, setViewStyle] = createSignal<string>('gallery');

    // When opening page check if there are upcoming events. If yes show them, if no show all events.
    // But only if user didn't land on the page with search params, as this means the
    // selected filters are intentional and should be respected.
    onMount(() => {
        if (eventsStore.landedWithSearchParams) return;

        const upcomingEvents = filterEvents();

        if (upcomingEvents.length > 0 && eventsStore.status.slug !== 'upcoming') {
            handleOnClickStatus('upcoming');
        }

        if (upcomingEvents.length === 0 && eventsStore.status.slug === 'upcoming') {
            handleOnClickStatus('all');
        }
    });

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;
    const breadcrumbs = () => `${localize('events', 'Events')} ${eventsStore.status?.name ? `/ ${statusNameToStatusTranslated(eventsStore.status?.name, localize, props.labels)}` : ''}`;
    const { handleOnClickStatus } = props.clickHelper;

    const filterEvents = () => {
        const propertyFiltered = filterByProperties();
        const statusFiltered = filterTemporally(propertyFiltered);
        return statusFiltered;
    };

    const filterTemporally = (events: EventListCardData[]) => {
        const status = eventsStore.status?.slug || 'all';

        const onDemandEvents: EventListCardData[] = [];
        const pastEvents: EventListCardData[] = [];
        const upcomingEvents: EventListCardData[] = [];

        events.forEach((event: EventListCardData) => {
            if (isOnDemandEvent(event)) {
                onDemandEvents.push(event);
            } else if (isPastEvent(event)) {
                pastEvents.push(event);
            } else {
                upcomingEvents.push(event);
            }
        });

        if (status === 'on-demand') {
            return onDemandEvents.reverse();
        }

        if (status === 'all') {
            return [
                ...upcomingEvents,
                ...onDemandEvents.reverse(),
                ...pastEvents.reverse(),
            ];
        }

        if (status === 'upcoming') {
            return [
                ...upcomingEvents,
                ...onDemandEvents.reverse(),
            ];
        }

        if (status === 'past') {
            return pastEvents.reverse();
        }

        return [];
    };

    const filterByProperties = createMemo(() => {
        const areaOfCare = eventsStore.areaOfCare?.slug || 'all';

        const filtered = props?.events
            ?.filter((event: EventListCardData) => {
                if (!props.isInternationalSite) return true;

                if (event?.hideFromGlobalEventsListing) return false;
                return true;
            })
            ?.filter((event: EventListCardData) => {
                if (areaOfCare === 'all') return true;
                return areaOfCare === event.areaOfCare;
            })
            ?.filter((event: EventListCardData) => {
                if (eventsStore?.selectedCategories?.length === 0) return true;
                const allCategorySlugs = eventsStore?.selectedCategories?.map((category: TopCategory) => category.slug);
                return !!event?.tags?.find((tag: string) => allCategorySlugs.includes(tag));
            })
            ?.filter((event: EventListCardData) => {
                if (eventsStore?.selectedTags?.length === 0) return true;
                const allTagSlugs = eventsStore?.selectedTags?.map((tag: Tag) => tag.slug);
                return event?.wordpressTags?.find((tag: Tag) => allTagSlugs.includes(tag.slug));
            })
            ?.filter((event: EventListCardData) => {
                if (eventsStore?.selectedLanguages?.length === 0) return true;
                return eventsStore?.selectedLanguages?.includes(languageCodes[event?.eventLanguage || '']);
            });
            
        return filtered;
    });

    const filteredEvents = () => filterEvents();

    createEffect(() => {
        const extractedUniqueLanguages = getUniqueLanguages(props.events);
        setAvailableLanguages(extractedUniqueLanguages);
    });

    const extractAndSetCategoriesAndTags = () => {
        const extractedTopCategories = extractTopCategories(filteredEvents());
        setAvailableCategories(extractedTopCategories);

        const extractedEventTags = getUniqueTags(filteredEvents());
        setAvailableTags(extractedEventTags);
    };
    extractAndSetCategoriesAndTags();

    createEffect(
        on(
            () => filteredEvents(),
            () => extractAndSetCategoriesAndTags()
        )
    );

    const listViewGroupedEvents = createMemo(() => {
        const onDemand: EventListCardData[] = [];

        const filtered = filteredEvents().reduce((result: Record<string, EventListCardData[]>, event) => {
            if (!event?.eventDate) return result;
            
            const date = new Date(event?.eventDate);
            const month = date.toLocaleString('en-US', { month: 'long' });
            const year = date.getFullYear();

            let key;
            if (isOnDemandEvent(event)) { // check eventExpireDate for backward compatibility
                onDemand.push(event);
            } else if (!Number.isNaN(year) && month !== 'Invalid Date') {
                key = month;

                // if year is not current year, add year to key
                if (year !== new Date().getFullYear()) {
                    key = `${month} '${year.toString().substring(2, 4)}`;
                }
            } 

            if (!key) return result;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(event);

            return result;
        }, {});

        return {
            filtered,
            onDemand,
        };
    });

    return (
        <ErrorCatcher componentName="Events container">
            <StyledEventsContainer>
                <Show when={!isMobile()}>
                    <Heading tag="h2" variant="breadcrumb">
                        {breadcrumbs()}
                    </Heading>
                </Show>

                <StyledFlexRow>
                    <ViewStyleButton
                        text={props.labels?.listView || 'List view'}
                        type="list"
                        onClick={() => setViewStyle('list')}
                        isActive={viewStyle() === 'list'}
                    />
                    <ViewStyleButton
                        text={props.labels?.galleryView || 'Gallery view'}
                        type="gallery"
                        onClick={() => setViewStyle('gallery')}
                        isActive={viewStyle() === 'gallery'}
                    />
                </StyledFlexRow>

                <StyledEventsList>
                    <Switch>
                        <Match when={viewStyle() === 'gallery'}>
                            <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [12], smallDesktop: [6, 6] }} inheritParentGrid={true}>
                                <For each={filteredEvents()}>
                                    {(event: EventListCardData) => createGroupedEventCard({ event })}
                                </For>
                            </Grid>
                        </Match>

                        <Match when={viewStyle() === 'list'}>
                            <For each={Object.keys(listViewGroupedEvents().filtered)}>
                                {(eventMonth) => (
                                    <>
                                        <Heading tag="h3" noBlockSpacing={true}>
                                            {eventMonth.split('-')[ 0 ]}
                                        </Heading>
                                        <For each={listViewGroupedEvents().filtered[ eventMonth ]}>
                                            {(event) => {
                                                return <ListViewEventCard event={event} />;
                                            }}
                                        </For>
                                    </>
                                )}
                            </For>

                            <Show when={listViewGroupedEvents().onDemand.length > 0}>
                                <Heading tag="h3" noBlockSpacing={true}>
                                    {localize('on-demand', 'On demand')}
                                </Heading>
                                <For each={listViewGroupedEvents().onDemand}>{(event) => (
                                    <ListViewEventCard event={event} />
                                )}</For>
                            </Show>
                        </Match>
                    </Switch>

                    <Show when={filteredEvents().length === 0 && listViewGroupedEvents().onDemand.length === 0}>
                        <Text fontSize="small" displayRedVerticalLine={false}>
                            {localize('no-events-found', 'No events found...')}
                        </Text>
                    </Show>
                </StyledEventsList>
            </StyledEventsContainer>
        </ErrorCatcher>
    );
};
