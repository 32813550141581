import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';

const { spacing } = theme;

type StyledPostCardProps = {
    orientation?: 'vertical' | 'horizontal';
    disableClickEvents?: boolean;
    inGrouping?: boolean;
    noBorder?: boolean;
    stale?: boolean; // Stale cards are greyed out
};

export const StyledPostCardWrapper = styled.div<{ large?: boolean; type?: string; }>`
    height: ${p => (p.large 
        ? '100%'
        : 'auto'
        // : p.type === 'event' ? '375px' : 'auto'
    )};
`;

export const StyledPostCard = styled(Link)<StyledPostCardProps>`
    display: flex;
    flex-direction: column;
    border-radius: 1.43rem;
    /* height: max-content; */
    height: 100%;
    min-height: 27.6rem;
    ${(p) =>
        p.noBorder
            ? ''
            : `
        border: 1px solid ${theme.palette.lightGray};
    `}
    width: ${(p) => (p.orientation === 'vertical' ? 'auto' : '100%')};
    background-color: white;
    list-style: none;
    position: relative;
    text-decoration: none;
    ${(p) =>
        !p.inGrouping
            ? `
        ${theme.mediaQueries.tabletMax} {
            margin-block: 2rem;
        }
        ${theme.mediaQueries.mobileMax} {
            margin-block: 1rem !important;
        }
    `
            : ''}

    ${(p) => (p.disableClickEvents ? 'pointer-events: none;' : '')}

    transition: transform 0.2s, box-shadow 0.2s;
    &:hover {
        box-shadow: 0.2rem 0.2rem 0.86rem 0px rgba(0, 0, 0, 0.09);
        transform: scale(0.97);
    }
    ${(p) =>
        p.stale
            ? `
            &:before {
                content: '';
                position: absolute;
                1op: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: ${theme.palette.lightGray};
                opacity: 50%;
                z-index: 1;
                border-radius: 1.43rem;
        
            }
    `
            : ''}
`;

export const StyledPostCardTop = styled.div<{ large?: boolean }>`
    position: relative;
    height: ${(p) => (p.large ? ' 28.6rem' : '19.3rem')};
`;

export const StyledPostCardBottom = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`;

export const StyledImage = styled.img<StyledImageProps>`
    border-radius: 1.37rem 1.37rem 0 0;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: ${(p) => (p.lowRes ? 1 : 0)};
    animation-delay: 0.05s;
    ${p => p.animate ? `
        animation: fadeOut 0.8s ease-out ${p.lowRes ? 'forwards' : 'backwards'};
    ` : `
        animation: none;
    `}

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

export const StyledImagePlaceholder = styled.div<StyledImageProps>`
    height: ${(p) => (p.large ? ' 28.6rem' : '19.3rem')};
    background-color: ${theme.palette.white};
    border-radius: 1.37rem 1.37rem 0 0;
    width: 100%;
    object-fit: cover;
    object-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-bottom: 1px solid ${theme.palette.lightGray};
`;

export type StyledImageProps = {
    large?: boolean;
    animate?: boolean;
    lowRes?: boolean;
};

export const StyledCardContent = styled.div`
    padding: ${spacing.xs} ${spacing.s} ${spacing.xs} ${spacing.s};
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledDate = styled.div`
    font-family: ${fontFamilyAtosRegular};
    font-size: 1.29rem;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    margin-bottom: 0.4rem;
    min-height: 3.8rem;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledCardOverlayDate = styled.div`
    font-family: ${fontFamilyAtosRegular};
    font-size: 1.29rem;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    margin-bottom: ${spacing.xs};

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledBottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 7rem;
`;

export const StyledCardTitle = styled.div<{ type?: string; }>`
    margin-bottom: ${spacing.xs};
    margin-right: ${(p) => (p.type === 'event' ? '0.7rem' : '1rem')};

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledLargeCardTitle = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 2.07rem;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    margin-bottom: ${spacing.xs};
    text-overflow: ellipsis;
    margin-right: 1rem;
    overflow: hidden;
`;

export const StyledLargeCardColumnsWrapper = styled.div`
    container-type: inline-size;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export const StyledLargeCardColumns = styled.div`
    padding-top: 3rem;
    padding-inline: 1rem;
    ${containerQueries('tabletSmallMax')} {
        & > * {
            min-width: unset;
            &:nth-child(1) {
                border-bottom: 1px solid ${theme.palette.red};
                padding-bottom: 1rem;
            }
            &:nth-child(2) {
                align-self: center;
                padding-top: 2rem;
            }
        }
    }
    ${containerQueries('tabletSmallMin')} {
        display: flex;
        & > * {
            min-width: 30%;
            &:nth-child(1) {
                border-right: 1px solid ${theme.palette.red};
                padding-right: 3rem;
            }
            &:nth-child(2) {
                align-self: center;
                padding-left: 3rem;
            }
        }
    }
`;

export const StyledOverlayContent = styled.div`
    background-color: white;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1.43rem;
    transition: ease-in-out 0.2s all;
    z-index: 1;

    &:hover {
        opacity: 1;
    }
`;

export const StyledOverlayContentInner = styled.div`
    position: absolute;
    top: 11%;
    left: 0;
    right: 0;
    bottom: 0;
    padding: ${spacing.s};
`;

export const StyledDescription = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.palette.darkGray};
    font-size: 1.29rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;

export const StyledOverlayBottomRow = styled.div`
    bottom: 1.64rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
`;

export const StyledLargeCardBottomRow = styled.div`
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`;

export const StyledReadMoreText = styled.div`
    color: ${theme.palette.red};
    font-size: 1.8rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    margin-right: 1rem;
`;
